import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from '@mui/styles';
import Slide from '@mui/material/Slide';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {Rotate as Hamburger} from 'hamburger-react';
import SportsOutlinedIcon from '@mui/icons-material/SportsOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {LanguageSwitcher} from '../LanguageSwitcher';

const useStyles = makeStyles((theme) => ({
  menuButton: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  menuContainer: {
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  menuItem: {
    marginBottom: theme.spacing(2),
  },
  menuItemText: {
    color: 'black',
    fontSize: 25,
    fontWeight: 'bold',
  },
}));

const BurgerMenu = ({isOpen, onToggle, onMenuItemClicked}) => {
  const {t} = useTranslation();
  const menuItems = [
    {
      key: 'games',
      label: t('common.footballGames'),
      path: '/games',
      disabled: false,
    },
    {
      key: 'tournaments',
      label: t('common.tournaments'),
      path: '/tournaments',
      disabled: false,
    },
    {
      key: 'leagues',
      label: t('common.leagues') + ' (Coming soon)',
      path: '/leagues',
      disabled: true,
    },
    {
      key: 'teams',
      label: t('common.teams'),
      path: '/teams',
      disabled: false,
    },
    {
      key: 'about',
      label: t('common.about'),
      path: '/about',
      disabled: false,
    },
    {
      key: 'contact',
      label: t('common.contact'),
      path: '/contact',
      disabled: false,
    },
  ];
  const menuRef = useRef(null);
  const classes = useStyles();


  // disables scrolling when menu is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  }, [isOpen]);

  return (
    <div>
      <Hamburger size={20} toggled={isOpen} toggle={onToggle} color={'black'}/>
      <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit style={{zIndex: -1}}>
        <div className={classes.menuContainer} ref={menuRef}>
          <List color={'black'} sx={{px: 3, mt: 8}}>
            {menuItems.map((item)=> (
              <ListItem
                key={item.key}
                className={classes.menuItem}
                button
                onClick={() => !item.disabled && onMenuItemClicked(item.path)}
                disabled={item.disabled}
              >
                <ListItemText classes={{primary: classes.menuItemText}} primary={item.label} />
              </ListItem>
            ))}
            <ListItem sx={{px: 1.5}}>
              <LanguageSwitcher/>
            </ListItem>
          </List>
        </div>
      </Slide>
    </div>
  );
};

export default BurgerMenu;
