import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {Box, Button, Typography, useMediaQuery} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import Background from '../../assets/backgrounds/background7.jpg';
import logo from '../../assets/blackRedFullLogo.png';
import {useTranslation} from 'react-i18next';
export const WelcomeContainer = () => {
  const theme = useTheme();
  const {t} = useTranslation();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box
      sx={{
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundColor: '#f5f5f7',
        backgroundImage: `url(${Background})`,
      }}
      minHeight={'40vh'}
      p={2}
      justifyContent={'center'}
      alignItems={smallScreen? 'flex-start' : 'center'}
      display={'flex'}
      flexDirection={'column' }
    >
      <Box
        component={'img'}
        alt="Forza90"
        src={logo}
        width={smallScreen? 190 : 250}
        alignSelf={'center'}
        // height={'10vh'}
      />

      <Box display={'flex'} flexDirection={'column'} mt={2}>
        <Typography
          fontWeight={'bold'}
          textAlign={'left'}
          mt={2}
          variant={smallScreen? 'h3': 'h2'}
          color={'whitesmoke'}
        >
          {t('welcomeContainer.playFootball')}

        </Typography>
        <Box display={'flex'} mt={1} pt={1} alignItems={'center'}>


          <Typography
            fontWeight={'bolder'}
            color={'whitesmoke'}
            textAlign={'left'}
            variant={smallScreen? 'h3': 'h2'}
          >
            {t('welcomeContainer.now')}
          </Typography>
          <Link to={'/games'} style = {{textDecoration: 'none'}}>
            <Button
              variant="contained"
              // size="large"
              style={{marginLeft: '10px'}}
              color={'secondary'}
            >
              {t('welcomeContainer.findGame')}
            </Button>
          </Link>
        </Box>
      </Box>

      {/* <Box*/}
      {/*  mt={2}*/}
      {/*  style={{*/}
      {/*    display: 'flex',*/}
      {/*    flexDirection: 'row',*/}
      {/*    justifyContent: 'center',*/}
      {/*  }}*/}
      {/* >*/}

      {/*  <Button*/}
      {/*    variant={'contained'}*/}
      {/*    size="large"*/}
      {/*    style={{margin: '10px'}}*/}
      {/*    color={'secondary'}*/}
      {/*    onClick={()=> navigate('/games/create')}*/}
      {/*  >*/}
      {/*    {t('welcomeContainer.hostGame')}*/}
      {/*  </Button>*/}
      {/* </Box>*/}
    </Box>
  );
};
